import React from "react"
import  "../styles/global.scss"
import { Container, Col, Row } from 'reactstrap';
// import Image from 'react-bootstrap/Image'
import Layout from "../layouts/index"
import { withPrefix } from "gatsby"
import { Helmet } from 'react-helmet';

const AboutPage  = () => (
	<Layout>
		<Helmet>
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
          <meta name="viewport" content="width=device-width; initial-scale=1.0; maximum-scale=1.0; user-scalable=0;" />
          <title>Interaid Pharmacy | About Us</title>
          <meta name="title" content="Interaid Pharmacy | About Us" />
		<meta name="description" content="Interaid Pharmacy is an independent pharmacy driven to provide its customers with the highest level of personalized customer service and care." />
          <link rel="canonical" href="www.interaidpharmacy.com.my/about" />

        </Helmet>

		<Container fluid="true" className="page-title-block about-page-title-block">
	        <Row>
	  			<span className="text-center font-dark text-bold-500">About Us</span>
	 		</Row>
	    </Container>
 
		<Container fluid="true" className="page-block">
			<div class="text-center block-center">
				Interaid Pharmacy is an independent pharmacy driven to provide its customers with the highest level of personalized customer service and care. We are dedicated to provide the suitable medical advice while ensuring that our customers receive a comfortable and pleasant pharmacy experience. We are constantly striving to maintain a commitment to customer satisfaction and stocking what customers want most.
				<br/><br/>Our vision is to be the leading online healthcare brand in Malaysia. 
				Interaid pharmacy aims to ensure aid is always available and reachable to our customers, just like the Internet!

			</div>
			<br/>
			<div class="image text-center"><img src={withPrefix('/assets/img/aboutus-new.jpg')} alt="About Us" height="70%" width="70%" class="img-fluid"/></div>
	
			<br/>
			<br/>
			<p class="text-center font-dark text-bold font-20"><span class="text-bold-700">Our</span> Pharmacist</p>
			<hr className="title-divider"/>
			<br/>
			<Row>
				<Col md={{ size: 6, offset: 1 }}> <span class="text-bold-500">Tan Zhihan</span> is a patient service-oriented pharmacist with more than 6 years of experience in retail 
				and pharmaceutical industry. Holding a Master degree of Pharmacy at University of Nottingham,
				 UK, he has a strong background in not only clinical pharmacology and patient medication management but
				  also in pharmaceutical care and pharmacodynamics. </Col>
				<Col md="4"><div class="image"><img src={withPrefix('/assets/img/pharmacist.jpg')} alt="About Us" height="300px" width="400px" class="img-fluid" /></div></Col>
				
			</Row>
			

		</Container>
	</Layout>
 )

export default AboutPage
